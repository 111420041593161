<template>
  <!-- 使用到 new Url() 的元件不能使用 SSR -->
  <ClientOnly>
    <div>
      <swiper
        v-bind="{...options}"
        @slideChange="onSwiper"
        class="brand-slider"
      >
        <template v-slot:container-start>
          <div class="d-flex justify-space-between mb-6">
            <div class="font-subtitle bold">{{ $t(Words.HomeNavInquiry) }}</div>
          <div class="actions">
            <v-btn icon="chevron_left" class="prevArrow" size="36" variant="text" :disabled="isBeginning" />
            <v-btn icon="chevron_right" class="nextArrow" size="36" variant="text" :disabled="isEnd" />
          </div>
          </div>
        </template>
        <swiper-slide
          v-for="item in brands"
          :key="item.name">
          <img :src="item.image" :alt="item.name" class="logo">
        </swiper-slide>
      </swiper>
    </div>
  </ClientOnly>
</template>

<script lang="ts">

export default defineComponent({
  data() {
    return {
      brands: [
        { name: 'Bloomberg', image: new URL('~/assets/images/slider-logos/Logo01.png', import.meta.url).href },
        { name: 'Credit Suisse', image: new URL('~/assets/images/slider-logos/Logo02.png', import.meta.url).href },
        { name: 'Morningstar', image: new URL('~/assets/images/slider-logos/Logo03.png', import.meta.url).href },
        { name: 'ISIN', image: new URL('~/assets/images/slider-logos/Logo04.png', import.meta.url).href },
        { name: 'eurek hedge', image: new URL('~/assets/images/slider-logos/Logo05.png', import.meta.url).href },
        { name: 'hfm', image: new URL('~/assets/images/slider-logos/Logo06.png', import.meta.url).href },
        { name: 'thomson reuter', image: new URL('~/assets/images/slider-logos/Logo07.png', import.meta.url).href },
        { name: 'hfr', image: new URL('~/assets/images/slider-logos/Logo08.png', import.meta.url).href },
        { name: 'allocator', image: new URL('~/assets/images/slider-logos/Logo09.png', import.meta.url).href },
        { name: 'preqin', image: new URL('~/assets/images/slider-logos/Logo10.png', import.meta.url).href },
        { name: 'CA', image: new URL('~/assets/images/slider-logos/Logo11.png', import.meta.url).href },
        { name: 'albourne', image: new URL('~/assets/images/slider-logos/Logo12.png', import.meta.url).href },
        { name: 'barclay hedge', image: new URL('~/assets/images/slider-logos/Logo13.png', import.meta.url).href },
        { name: 'evestment', image: new URL('~/assets/images/slider-logos/Logo14.png', import.meta.url).href },
        { name: 'Cogent', image: new URL('~/assets/images/slider-logos/Logo15.png', import.meta.url).href },
        { name: 'UG', image: new URL('~/assets/images/slider-logos/Logo16.png', import.meta.url).href },
        { name: '經濟日報', image: new URL('~/assets/images/slider-logos/Logo17.png', import.meta.url).href },
      ],
      options: {
        navigation: {
          nextEl: '.nextArrow',
          prevEl: '.prevArrow',
        },
        autoplay: {
          delay: 8000,
        },
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 24,
          },
          976: {
            slidesPerView: 4,
          },
          1280: {
            slidesPerView: 5,
          }
        },
      },
      isBeginning: true,
      isEnd: false,
    }
  },
  methods: {
    onSwiper(swiper: any) {
      this.isBeginning = swiper.isBeginning
      this.isEnd = swiper.isEnd
    },
  }
})
</script>

<style lang="stylus" scoped>
.brand-slider
  img.logo
    display block
    margin auto
    height 60px
    filter grayscale(1)
    opacity .6
    transition .5s
    &:hover
      filter grayscale(0)
      opacity 1
</style>