<template>
  <div v-if="listData.length && isVisible" class="marquee">
    <div class="main" @click="openModal">
      <div class="slider-box">
        <swiper v-bind="{...options}" ref="slider">
          <swiper-slide v-for="(data, index) in listData" :key="index">
            <div class="marquee-text">
              <div class="font-body">{{ data.content }}</div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <v-btn icon="close" size="36" variant="plain" color="#00000099" @click="close"></v-btn>

    <!-- 公告 -->
    <ModalBulletin ref="bulletin" />
  </div>
</template>

<script lang="ts" setup>
import { loadNotify } from '@/api/about'
import moment from 'moment'

const options = {
  autoplay: {
    delay: 5000,
  },
  direction: 'vertical',
  autoHeight: true,
  loop: true
}
const rawData = ref()
const bulletin = ref()
const closedAt = useCookie('close_marquee_time')
const isVisible = computed(() => {
  if (closedAt.value) {
    // 手動關閉後，必須隔天才會再出現
    return moment().isAfter(closedAt.value, 'day')
  }
  return true
})

const listData = computed(()=> {
  if (!rawData.value) return []
  return rawData.value.slice(0,5)
})

function openModal() {
  if (bulletin?.value) {
    bulletin?.value.open()
  }
}

function close() {
  closedAt.value = String((new Date()).getTime()) // 使用 timestamp 比較短
}

await loadNotify().then(({data, error}) => {
  if (data.value) {
    rawData.value = data.value.entries
  } else {
    handleFatal(error)
  }
})

</script>

<style lang="stylus" scoped>
.marquee
  display flex
  align-items center
  gap 16px
  padding 8px 32px
  background-color $gray-lighten-5
  +under(md)
    padding 8px 12px
  .main
    flex-grow 1
    color $black-0-6
  .slider-box
    max-height 24px
    overflow hidden
    +under(md)
      max-height 48px
  .marquee-text
    display -webkit-box
    -webkit-box-orient vertical
    -webkit-line-clamp 1
    text-overflow ellipsis
    overflow hidden
    +under(md)
      -webkit-line-clamp 2
</style>

