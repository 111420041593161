<template>
  <v-dialog v-model="dialog" max-width="800">
    <div class="modal-cont">
      <h5 class="bold text-center">{{ $t(Words.MenuAnnouncement2) }}</h5>
      <ul v-if="listData.length" class="list">
        <li v-for="(data, index) in listData" :key="index">
          <h6>
            <nuxt-link v-if="data.url" :to="linkTo(data.url)" @click="close" class="link-style">{{ data.content }}</nuxt-link>
            <template v-else >{{ data.content }}</template>
          </h6>
        </li>
      </ul>
      <div class="actions">
        <v-btn
          color="#F5F5F5"
          flat
          block
          size="x-large"
          class="font-weight-bold"
          @click="close"
        >
          {{ $t(Words.Close) }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script lang="ts" setup>
import { loadNotify } from '@/api/about'

let dialog = ref(false)
const rawData = ref()
const { english: enMode } = useViewMode()

const listData = computed(()=> {
  if (!rawData.value) return []
  return rawData.value.slice(0,5)
})
const open = () => {
  dialog.value = true
}
const close = () => {
  dialog.value = false
}

defineExpose({
  open,
  close,
})

await loadNotify().then(({data, error}) => {
  if (data.value) {
    rawData.value = data.value.entries
  } else {
    handleFatal(error)
  }
})

function linkTo(url:string) {
  const lang = enMode.value ? 'en' : 'zh'
  return `/${lang}${url}`
}

</script>

<style lang="stylus" scoped>
.modal-cont
  background #fff
  padding 48px
  max-height 80vh
  overflow-y auto
  +under(sm)
    padding 24px
  .list
    margin 48px auto
    padding-left 25px
    li+li
      margin-top 8px
  .link-style
    cursor pointer
    &:hover
      text-decoration underline
</style>