<!-- 首頁:風控流程圖 -->
<template>
  <!-- 使用到 new Url() 的元件不能使用 SSR -->
  <ClientOnly>
    <div class="graphic-wrap">
      <div class="buttons desktop">
        <div class="button-prev">
          <v-btn icon="chevron_left" size="36" variant="text" />
        </div>
        <div class="button-next">
          <v-btn icon="chevron_right" size="36" variant="text" />
        </div>
      </div>

      <div class="swiper-wrap">
        <swiper
          v-bind="{...options}"
          class="slider" >
          <swiper-slide v-for="graphic in graphics" :key="graphic.title" class="index-graphic-box">
            <div class="list">
              <div v-for="item in graphic.list" :key="item.text" class="list-item">
                <h5 class="bold text-center flex-grow-1">
                  {{ $t(item.text) }}
                </h5>
                <div class="icon-box">
                  <img :src="item.image" :alt="item.text" >
                </div>
              </div>
            </div>
            <h5 class="title">
              {{ $t(graphic.title) }}
            </h5>
          </swiper-slide>
        </swiper>
      </div>
      <div class="buttons mobile">
        <div class="button-prev">
          <v-btn icon="chevron_left" size="52" variant="text" />
        </div>
        <div class="button-next">
          <v-btn icon="chevron_right" size="52" variant="text" />
        </div>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
const deviceView = useDeviceView()

const options = computed(() => {
  return {
    spaceBetween: 20,
    navigation: {
      nextEl: '.button-next',
      prevEl: '.button-prev',
      disabledClass: 'disabled',
    },
    autoplay: {
      delay: 8000,
    },
    slidesPerView: 'auto',
  }
})

const graphics = [
  {
    title: Words.HomeRiskCaption1,
    list: [
      { text: Words.HomeRiskFeature1, image: new URL('~/assets/images/risk-icon/1-1.png', import.meta.url).href },
      { text: Words.HomeRiskFeature2, image: new URL('~/assets/images/risk-icon/1-2.png', import.meta.url).href },
      { text: Words.HomeRiskFeature3, image: new URL('~/assets/images/risk-icon/1-3.png', import.meta.url).href },
    ]
  },
  {
    title: Words.HomeRiskCaption2,
    list: [
      { text: Words.HomeRiskFeature4, image: new URL('~/assets/images/risk-icon/2-1.png', import.meta.url).href },
      { text: Words.HomeRiskFeature5, image: new URL('~/assets/images/risk-icon/2-2.png', import.meta.url).href },
    ]
  },
  {
    title: Words.HomeRiskCaption3,
    list: [
      { text: Words.HomeRiskFeature6, image: new URL('~/assets/images/risk-icon/3-1.png', import.meta.url).href },
      { text: Words.HomeRiskFeature7, image: new URL('~/assets/images/risk-icon/3-2.png', import.meta.url).href },
    ]
  },
  {
    title: Words.HomeRiskCaption4,
    list: [
      { text: Words.HomeRiskFeature8, image: new URL('~/assets/images/risk-icon/4-1.png', import.meta.url).href },
      { text: Words.HomeRiskFeature9, image: new URL('~/assets/images/risk-icon/4-2.png', import.meta.url).href },
    ]
  },
]


</script>

<style lang="stylus" scoped>
.graphic-wrap
  position relative
  margin auto
  margin-top 72px
  +under(md)
    margin-top 48px
.index-graphic-box
  width 400px
  height 480px
  padding 24px
  display flex
  flex-direction column
  border 1px solid $black-0-12
  background-color $gray-lighten-5
  border-radius 8px
  box-sizing border-box
  user-select none
  +under(md)
    width 320px
  .title
    text-align center
    padding-top 24px
    color $black-0-42
  .list
    flex-grow 1
    display flex
    flex-direction column
    background-color #fff
  .list-item
    flex 1 1 0
    display flex
    align-items center
    padding 16px
    +.list-item
      border-top 1px solid $black-0-12
  .icon-box
    width 60px
    margin 0 10px
    img
      width 100%
.buttons
  display flex
  justify-content center
  .disabled
    color $black-0-26
  &.desktop
    position absolute
    right 0
    top calc(-72px - 40px)
    +under(md)
      display none
  &.mobile
    margin-top 24px
    display none
    +under(md)
      display flex
.swiper-wrap
  position relative
</style>
