<template>
  <div v-if="awards.length" class="slider-group">
    <div class="header font-body-2">{{ $t(Words.MenuAward) }}</div>
    <div>
      <VueMarqueeSlider
        id="marquee-slider-loop"
        :speed="speed"
        :paused="isTouch"
        :repeat="20"
        :space="0"
        autoWidth
      >
        <div 
          v-for="award in awards" :key="award" class="content" 
          @mouseenter="handleTouch(true)" 
          @mouseleave="handleTouch(false)">
          <v-icon icon="hotel_class" class="mr-3" color="#00000061" />
          <nuxt-link class="font-body link-effect" :to="localePath({name: 'awards'})">
            {{ award }}
          </nuxt-link>
        </div>
      </VueMarqueeSlider>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { VueMarqueeSlider } from 'vue3-marquee-slider'
import '@/node_modules/vue3-marquee-slider/dist/style.css'

const props = defineProps({
  awards: {
    type: Array,
    default: []
  },
})
const { awards } = toRefs(props)
const localePath = useLocalePath()
const deviceView = useDeviceView()

const isTouch = ref(true)
const handleTouch = function(val:boolean) {
  isTouch.value = val
}
const speed = computed(() => {
  const width = deviceView.width
  return 1000*(width/25)
})

const init = function() {
  // fix 套件 bug: 初始化時動畫會超級快，跟設定的速度不一致
  setTimeout(() => {
    handleTouch(false)
  }, 500)
}

init()

</script>

<style lang="stylus" scoped>
.slider-group
  padding 24px 0
  background $gray-lighten-5
  .header
    text-align center
    color $black-0-6
  .content
    display flex
    align-items center
    padding 16px
    white-space nowrap
    a
      display block
      cursor pointer
</style>