<template>
  <div class="wrap">
    <div class="menu">
      <div class="fixed">
        <slot name="title"></slot>
        <component :is='deviceView.desktop ? "h4" : "h3"' class="bold mb-6" :class="deviceView.mobile ? 'text-center' : ''">
          {{ $t(Words.AboutHistory) }}
        </component>
        
        <v-tabs
          v-model="current.tab"
          :color="deviceView.desktop ? 'black' : 'primary'"
          :align-tabs="deviceView.desktop ? 'start' : 'center'"
          :direction="deviceView.desktop ? 'vertical' : 'horizontal'"
          :grow="deviceView.desktop ? false : true"
        >
          <v-tab v-for="tab in tabs" :key="tab.id" :value="tab.id" v-track:click="tab.track" class="font-subtitle bold">
            {{ $t(tab.name) }}
          </v-tab>
        </v-tabs>
        <Mouse v-if="deviceView.desktop" class="mt-12" />
      </div>
    </div>
    <div class="timeline">
      <v-window v-model="current.tab">
        <v-window-item v-for="tab in tabs" :key="tab.id" :value="tab.id">
          <div class="timeline-content">
            <div v-for="item in list" :key="`${item.date}-${item.title}`" class="point-wrap">
              <div class="point">
                <div class="time font-subtitle" :class="{'type-company': item.type === 'company'}">
                  <div>{{ moment(item.date).year() }}</div>
                  <div>{{ getMonth(item.date) }}</div>
                </div>
                <div>
                  <div class="title font-subtitle bold">
                    {{ item.title }}
                  </div>
                  <div v-if="item.content" class="font-subtitle2 detail">{{ item.content }}</div>
                </div>
              </div>
            </div>
          </div>
        </v-window-item>
      </v-window>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IMilestone } from '@/api/about'
import moment from 'moment'

const props = defineProps({
  data: {
    type: Array,
    default: []
  },
})
const { data } = toRefs(props)

const deviceView = useDeviceView()
const current = ref({
  tab: 'all'
})
const tabs = [
  { name: Words.HomeAll, id: 'all', track: '首頁,history:all' },
  { name: Words.HomeMilestones, id: 'company', track: '首頁,history:milestones' },
  { name: Words.HomeDevelopment, id: 'fund', track: '首頁,history:development' },
]
const list:IMilestone[] = computed(()=> {
  const tab = current.value.tab
  if (tab === 'all') {
    return data.value
  } else {
    return data.value.filter(item => item.type === tab)
  }
})

const getMonth = function(date:string) {
  const month = moment(date).month() + 1 
  return month.toString().padStart(2, '0')
}

</script>
 
<style lang="stylus" scoped>
.wrap
  +over(md)
    display flex
    gap 24px
  .menu
    flex 1 1 0
  .fixed
    position sticky
    top 16px
  .timeline
    flex 2 1 0
    +under(md)
      margin-top 48px
  .timeline-content
    +over(lg)
      display flex
      flex-wrap wrap
  .point-wrap
    display flex
    flex 0 0 50%
    padding-bottom 20px
    margin-bottom 20px
    border-bottom 1px solid $black-0-12
    +over(lg)
      &:nth-child(odd)
        padding-right 12px
      &:nth-child(even)
        padding-left 12px
  .point
    display flex
    align-items center
    padding 12px
    .time
      background #ECEFF1
      padding 4px 8px
      color $black-0-6
      text-align center
      margin-right 16px
      &.type-company
        background linear-gradient(145.06deg, #1469CD 0%, #0B548F 100%)
        color #fff
    .title
      color $black-0-87
    .detail
      color $black-0-6
      margin-top 8px

// 客製桌機tab
:deep(.menu) 
  .v-slide-group--vertical 
    .v-btn__content
      padding-left 10px
      color $black-0-6
      text-transform none
    .v-slide-group-item--active
      .v-btn__content
        font-weight bold
        color $black-0-87
    .v-tab__slider
      width 0
      &:before
        content '➤'
        position absolute
        top 50%
        transform translateY(-50%)
  .v-tabs--horizontal
    .v-btn:not(.v-tab--selected)
      color $black-0-54
    .v-btn__content
      font-size 15px
      font-weight bold
      text-transform none
</style>